<template>
  <div>
    <lixiangmanger :ddata="form"></lixiangmanger>

    <div class="flexrow" v-if="formStatus > 1.40 || formStatus <= 5.30">
      <div class="leftcg">
        <div class="flexrow lefttie" style="background-color: #ffffff">
          <div class="ltleft">
            审查资料
          </div>
        </div>
        <div class="leftfile">
          <div class="filelistcg">
            <div v-for="(item, index) in checkdata.uploadfiles" class="cgfiles" :key="index">
              <div class="flexrow cgtop">
                <div class="cgfleft">
                  <div>
                    {{ item.name }}
                    <el-tag v-if="item.isupload" effect="dark" size="mini" type="danger">必须上传</el-tag>
                  </div>
                </div>
                <div class="cgfrig">
                  <el-upload accept=".pdf,.jpg,.png,.doc,.docx" :before-upload="showLoad"
                    :data="{ token: $store.state.token }" :file-list="item.fileList"
                    :on-success="(response) => changeMaterImg(item, response)" :show-file-list="false"
                    :on-remove="(file, fileList) => removeImg(item, file, fileList)" action="api/public/bupload"
                    class="upload-demo">
                    <el-button v-if="isadmin!=1" size="small" type="primary" :disabled="!isValueInList">上传文件 </el-button>
                  </el-upload>
                </div>
              </div>
              <div class="flexrow cgtop" v-for="(file, fileIndex) in item.fileList" :key="fileIndex">
                <div class="cgfleft">
                  <div>
                    <div class="filename" @click="viewFile(file)">
                      {{ file.name }}
                    </div>
                  </div>
                </div>
                <div class="cgfrig" v-if="isadmin!=1" @click="delbtn(item, fileIndex)" :disabled="!isValueInList">删除</div>
              </div>
            </div>

          </div>
        </div>
        <div v-if="isadmin == 1" class="sendbtn">
          <!-- 这里是管理员提交的按钮 -->
          <!-- <el-button type="success" @click="saveProject()">通过</el-button> -->
          <!-- <el-button type="danger" @click="bulixiang">不通过</el-button> -->
          <!-- <el-button type="primary" @click="changeStatus()">退回修改</el-button> -->
          <el-button type="info" @click="gotoStep()">下载认证表</el-button>
          <el-button type="success">资料打包下载</el-button>
        </div>
        <div v-else class="sendbtn">
          <!-- 这里是用户显示的状态 -->
          <el-button type="success" @click="addmit" :disabled="disabled" v-if="isValueInList">提交形式检查</el-button>
          <el-button type="info" :disabled="todisabled" v-if="checkListstatus.includes(form.status)">形式检查中</el-button>
          <el-button type="success" :disabled="todisabled" v-else>形式检查已完成</el-button>
        </div>
      </div>
      <div class="rightcg">
        <div class="lefttie" style="margin-bottom: 10px; border-bottom: none">
          <div class="ltleft">审查意见</div>
        </div>
        <div v-if="isadmin == 1">
          <el-timeline :reverse="reverse">
            <el-timeline-item v-for="(activity, index) in stagecomments" :key="index" :style="index == stagecomments.length - 1
              ? 'color: #409EFF'
              : 'color: #999999'
              ">
              <div class="flex-row">
                <div class="left-group">
                  <div class="checktitle">
                    {{ activity.type | shenchaType }}
                  </div>
                  <div class="lttime">
                    {{ activity.created_at | formatDate }}
                  </div>
                </div>

                <div class="shehe">审核人：{{ activity.uname }}</div>

              </div>
              <div class="kuang">
                <div class="cr-card">
                  <div class="checkcont">
                    {{ activity.content }}
                  </div>
                  <div class="chfiles">
                    <div class="cgfleft" v-for="(item, index) in activeFile(activity.type)" :key="index">
                      <el-tooltip class="item" effect="light" :content="item.name" placement="bottom">
                        <div class="cgfleftto" @click="viewFile(item)">
                          {{ item.name }}
                        </div>
                      </el-tooltip>
                    </div>
                  </div>
                </div>

              </div>
            </el-timeline-item>

            <el-timeline-item color="#409EFF">
              <div class="checktitle" style="color: #409eff">
                {{ nowType | shenchaType(newType) }}
              </div>
              <div class="kuang">
                <div class="cr-card">
                  <div class="checkcont">
                    <el-input type="textarea" :rows="2" style="min-height: 100px" placeholder="请输入意见" v-model="content">
                    </el-input>
                  </div>
                  <div class="flexrow filebtn">
                    <div class="ltmanger">
                      <div v-for="(item, index) in checkFiles" :key="index" class="listrow">
                        <div style="width: 90%" class="listrownam">
                          {{ item.name }}
                        </div>
                        <div v-if="checkFiles.length > 0">
                          <i @click="delfiles(index)" class="el-icon-close" style="color: #f56c6c; cursor: pointer"></i>
                        </div>
                      </div>
                    </div>
                    <div class="lttime">
                      <el-upload :data="{ token: $store.state.token }" :file-list="checkFiles"
                        :on-success="(response) => uploadCheckFiles({ orderid: 1 }, response)" :show-file-list="false"
                        action="api/public/bupload" class="upload-demo" accept=".png,.jpg,.pdf">
                        <el-button size="mini" type="primary">上传</el-button>
                      </el-upload>
                    </div>
                  </div>
                  <div class="flowrow">
                    <div class="flowleft">意见模板</div>
                    <div class="flowright">
                      <i class="el-icon-circle-plus-outline" @click="dialogVisible = true"></i>
                    </div>
                  </div>
                  <div class="checkcont moban">
                    <div v-for="(item, index) in mblist" :key="index">
                      <div class="mbline">
                        <div class="neirong">
                          <el-tooltip class="item" effect="light" :content="item.content" placement="bottom">
                            <div class="neirongto" @click="changeContent(item)">
                              {{ item.content }}
                            </div>
                          </el-tooltip>
                        </div>
                        <div class="delbtn" @click="delmblist">
                          <i class="el-icon-close" style="color: #f56c6c"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-timeline-item>
          </el-timeline>
          <!-- {{ checkListstatus.includes(form)}} -->
          <div class="flexrow tgbtn">
            <el-button type="success" @click="checkpass" v-if="checkListstatus.includes(form.status)">审核</el-button>
            <el-button type="success" disabled v-if="disagreeListstatus.includes(form.status)">审核</el-button>
            <el-button type="danger" disabled v-if="disagreeListstatus.includes(form.status)">已退回上一流程</el-button>
            <el-button type="danger" @click="tuihui" :disabled="disabled"
              v-if="checkListstatus.includes(form.status)">退回</el-button>
          </div>
        </div>
        <div v-else>
          <!-- TODO 这里是给企业用户查看的回复意见 -->
        </div>
      </div>
      <el-dialog title="意见模板" :visible.sync="dialogVisible" width="50%">
        <el-input type="textarea" :rows="2" placeholder="请输入意见模板" v-model="mbcontent">
        </el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addMuban">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="文件预览" :visible.sync="fileViewVisible" width="80%" :before-close="handleClose">
        <div v-if="selectedFile">
          <p><strong>文件名称: </strong>{{ selectedFile.name }}</p>
          <p><strong>文件URL: </strong><a :href="selectedFile.url" target="_blank">{{ selectedFile.url }}</a></p>

          <!-- 图片预览 -->
          <img v-if="isImage(selectedFile.url)" :src="selectedFile.url" alt="文件图片预览"
            style="max-width: 100%; margin-top: 20px;" />

          <!-- PDF 预览 -->
          <div v-else-if="isPDF(selectedFile.url)" style="margin-top: 20px;">
            <embed :src="selectedFile.url" type="application/pdf" width="100%" height="600px" />
          </div>

          <!-- 其他文件类型提示 -->
          <p v-else style="margin-top: 20px;">无法预览此类型的文件。</p>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="fileViewVisible = false">关闭</el-button>
        </span>
      </el-dialog>


    </div>

  </div>
</template>
<script>
import { enterpriseFiles, enterpriseFileDel, addBiaozhun, mubanlist, addmuban, enterpriseExamine, delmuban } from "@api/project";
import { formatDate, shenchaType } from '@utils/filters';
import lixiangmanger from "./lixiangmanger";

export default {
  components: { lixiangmanger },
  props: {
    ddata: {
      type: Object,
      required: true,
    }
  },
  filters: {
    shenchaType,
    formatDate,
  },
  computed: {
    downloadLink() {
      return this.checkdata.templete.file;
    },
    isValueInList() {
      return this.disagreelist.includes(this.formStatus);
    },
  },
  data() {
    return {
      dialogVisible: false,
      fileViewVisible: false,
      selectedFile: "",
      isadmin: "",
      token:"",
      id:"",
      checkListstatus: ['1.20', '1.40', '2.10', '2.30', '2.50','2.90'],
      disagreelist: ['1.40', '2.20', '2.40', '2.60'], //当这里面数值时可以提交数据
      disagreeListstatus: ['2.20', '2.40', '2.60'],

      // 审核资料数据结构
      checkdata: {
        "stage": "formalCheck",
        "stagetypeid": '1',
        "templete": {
          "name": "模板下载",
          "file": "https://api.gbservice.cn/storage/uploads/standard/5ae5061594f0899cec0c7392580a5492.zip",
          "delaytime": 8
        },
        "uploadfiles": [
          {
            "orderid": 1,
            "name": "江苏省工程建设企业技术标准/标准设计认证承诺书",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 2,
            "name": "江苏省工程建设企业技术标准/标准设计认证委托书",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 3,
            "name": "企业营业执照及相关资质证书复印件",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 4,
            "name": "企业技术标准/标准设计批准发布文件",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 5,
            "name": "企业技术标准/标准设计审查意见和审查人员名单表",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 6,
            "name": "修编说明（修订时上传）",
            "isupload": false,
            "fileList": []
          },
          {
            "orderid": 7,
            "name": "江苏省工程建设企业技术标准/标准设计文本（含条文说明）",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 8,
            "name": "编制说明、执行相关标准情况说明、研究报告和有关设计计算书等",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 9,
            "name": "企业标准信息公共服务平台发布的企业标准",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 10,
            "name": "有资质的检测机构出具的性能检测报告",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 11,
            "name": "企业技术标准/标准设计中涉及或未涉及专利的声明",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 12,
            "name": "工程建设、设计、施工、监理等单位联合签证的在省内2个以上工程应用证明或验收报告",
            "isupload": true,
            "fileList": []
          }
        ]
      },
      nowType: 1,
      reverse: false,
      types: [],
      activities: [],
      form: {},
      disabled: false,
      todisabled: true,
      loadingInstance: "",
      formStatus: 0,
      stageid: 2,
      stagecomments: [], //本阶段的意见 
      mbcontent: "",
      mblist: [],
      uploadedImages: [],
      content: '',
      newType: '',
      statusform: {},
      checkFiles: [], //审核过程中上传的问题

    };
  },
  mounted() {

    this.form = this.$props.ddata;
    
    // this.checkFiles=this.form.files;
    this.$watch('ddata', (newVal) => {
      this.form = newVal;
      this.formStatus = this.form.status;
      this.stageid = parseInt(this.formStatus);
      this.getfiles();
      // 这里需要筛选当前阶段的评审意见
      this.filterComments();
      this.nowType = newVal.type;
    });
    this.nowType=this.form.type;
    // console.log(this.nowType)
    // 获取token
    this.getIdAndToken();
    this.mubanList();
    // this.getinfo();
  },
  methods: {
    // 删除文件
    delfiles(index) {
      this.checkFiles.splice(index, 1); // Remove the file at the given index
    },
    // 判断是否为PDF类型
    isPDF(fileUrl) {
      return /\.pdf$/i.test(fileUrl);
    },
    // 判断是否为图片类型
    isImage(fileUrl) {
      return /\.(jpg|jpeg|png|gif)$/i.test(fileUrl);
    },
    viewFile(file) {
      this.selectedFile = file;
      this.fileViewVisible = true;
    },

    // 判断当前状态并给定不同的参数
    statuscheck(statusNumber, addparam) {
      let params = {
        status: '',
        after_status: ''
      };
      if (statusNumber && !isNaN(statusNumber)) {
        params.status = statusNumber + addparam;
        params.after_status = statusNumber + addparam;
      }
      return params;
    },
    //初审退回
    tuihui() {
      // 当前状态
      const statusNumber = parseInt(this.form.status);

      let params = {
        token: this.token,
        sid: this.id,
        status: "",
        after_status: "",
        content: this.content,
        type: 1,
        examinetype: 0,
      };
      this.$confirm('确认退回本标准？')
        .then(_ => {
          this.disabled = true;
          if (this.nowType == 1) { //初审退回到用户
            params.type = 1;
            let getp = this.statuscheck(statusNumber, '.20'); //TODO 这里注意下在抽检中增加5.05用户填写资料的状态
            params.status = getp.status;
            params.after_status = getp.after_status;

            enterpriseExamine(params)
              .then((res) => {
                if (this.nowType == 7) {
                  this.$message.success("标准终止，标准信息留档");
                } else {
                  this.$message.success("退回到用户");
                }
              })
              .catch((err) => {
                this.disabled = true;
              });

          } else if (this.nowType == 2) { //复审退回到初审
            params.type = 1;
            let getp = this.statuscheck(statusNumber, '.10');
            params.status = getp.status;
            params.after_status = getp.after_status;

            enterpriseExamine(params)
              .then((res) => {
                this.$message.success("退回到初审");
              })
              .catch((err) => {
                this.disabled = true;
              });

          } else if (this.nowType == 3) { //终审退回到复审
            params.type = 2;
            let getp = this.statuscheck(statusNumber, '.50');         
            params.status = getp.status;
            params.after_status = getp.after_status;
            enterpriseExamine(params)
              .then((res) => {
                this.$message.success("退回到复审");
              })
              .catch((err) => {
                this.disabled = true;
              });

          }
        })
        .catch(_ => { });
    },
    delmblist() {
      let mbid = this.mblist[0].id;
      let params = {
        token: this.token,
        id: mbid,
      };
      delmuban(params).then((res) => {
        this.$message.success("删除成功");
        this.dialogVisible = false;
        this.getIdAndToken();
        this.mubanList();
      });
    },
    changeContent(item) {
      this.content = item.content;
      // console.log(item)
    },
    //初审通过
    checkpass() {
      // 定义提交参数
      let params = {
        token: this.token,
        sid: this.id,
        status: "",
        content: this.content,
        type: "",
        examinetype: "",
        after_status: "",
        files: this.checkFiles,
      };
      this.disabled = true;
      // 当前状态
      const statusNumber = parseInt(this.statusform.number);

      // 添加orderid字段
      this.checkFiles.forEach((item, index) => {
        item.stageid = statusNumber;
      });

      // 初审状态
      if (this.nowType == "1") {
        this.checkFiles.map((item) => (item.type = 30));
        params.status = '2.30';
        params.after_status = '2.30';
        params.type = "2";
        params.examinetype = "1";
        enterpriseExamine(params)
          .then((res) => {
            this.$message.success("初审通过！");
          }).catch((err) => {
            this.disabled = false;
          });
        // 复审状态
      } else if (this.nowType == "2") {
        this.checkFiles.map((item) => (item.type = 31));
        params.status = '2.50';
        params.after_status = '2.50';
        params.type = "3"
        params.examinetype = "2";
        enterpriseExamine(params)
          .then((res) => {
            this.$message.success("复审通过！");
          }).catch((err) => {
            this.disabled = false;
          });

        // 终审状态
      } else if (this.nowType == "3") {
        this.checkFiles.map((item) => (item.type = 32));
        params.status = '2.90';
        params.after_status = "2.90"; //这里修改成直接进行专家评审，评审完成后再进行提交资料
        params.type = "7"
        params.examinetype = "3";
        enterpriseExamine(params)
          .then((res) => {
            this.$message.success("终审通过！");
          }).catch((err) => {
            this.disabled = false;
          });

      } else if (this.nowType == "7") { //汇总意见
        this.checkFiles.map((item) => (item.type = 32));
        params.status = '2.90';
        params.after_status = "3.10";
        params.type = "1"
        params.examinetype = "3";
        enterpriseExamine(params)
          .then((res) => {
            this.$message.success("意见汇总完成！");
          }).catch((err) => {
            this.disabled = false;
          });
      }

    },
    mubanList() {
      let params = {
        token: this.token,
        page: 1,
        limit: 15,
      };
      mubanlist(params).then((res) => {
        this.mblist = res.data;
      });
    },

    // 筛选当前审核意见
    filterComments() {
      this.stagecomments = this.form.examines.filter(c => c.status > 1.4 && c.status <= 3.0 && c.content != null);
      // console.log(this.stagecomments);
    },
    // 不通过
    bulixiang() {
      if (this.form.status == 2) {
        this.form.status = 5;
        this.form.token = this.token;
        addBiaozhun(this.form).then((rec) => {
          this.$router.push({
            path: "../../formal/formalPend",
          });
          this.$message.warning("项目不通过！");
        });
      } else {
        this.$message.warning("项目状态不支持不通过");
      }
    },
    //通过
    saveProject() {
      if (this.form.status == '1.20') {
        this.add_batch();
      } else {
        this.$message.warning("项目状态不支持通过");
      }
    },
    // 填写项目编码后立项
    add_batch() {
      this.form.number = this.number;
      this.form.status = '1.40';
      this.form.token = this.token;
      addBiaozhun(this.form).then((rec) => {
        this.batch_show = false;
        this.$message({
          showClose: true,
          message: "立项成功",
          type: "success",
        });
        //立项成功到初审
        this.$router.push({
          path: "../../formal/formalPend",
        });
      });

    },
    //退回 修改状态
    changeStatus() {
      this.form.status = '1.30';
      this.form.token = this.token;
      console.log(this.form);
      addBiaozhun(this.form).then((rec) => {
        // console.log(rec);
      });
      this.$router.push({
        path: "../../formal/formalPend",
      });
      this.$message({
        message: "已退回给用户",
        type: "success",
      });
    },
    // 将获取的标准列表的文件
    getfiles() {
      if (this.form.files.length != 0) {
        this.form.files.forEach((file) => {
          if (file.type == this.checkdata.stagetypeid) {
            const uploadFile = this.checkdata.uploadfiles.find((f) => f.orderid === file.orderid);
            if (uploadFile) {
              uploadFile.fileList.push(file);
            }
          }
        });

      }

    },
    zhanshi2(item) {
      window.open(item.url);
    },
    zhanshi(item) {
      window.open(item.url);
    },
    activeFile(type) {
      if (type == 1 || type == 4) {
        return this.form.files.filter((item) => item.type == 30);
      } else if (type == 2 || type == 5) {
        return this.form.files.filter((item) => item.type == 31);
      } else if (type == 3 || type == 6) {
        return this.form.files.filter((item) => item.type == 32);
      } else if (type == 7) {
        return this.form.files.filter((item) => item.type == 33);
      }
    },
    addMuban() {
      let params = {
        token: this.$store.state.token,
        content: this.mbcontent,
        type: "1",
      };
      addmuban(params).then((res) => {
        this.$message.success("添加成功");
        this.dialogVisible = false;
        this.getIdAndToken();
      });
    },

    // 上传之前进行格式和文件大小限制
    showLoad(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = ["pdf", "jpg", "png", "doc", "docx"];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message("上传文件只能是 pdf、jpg、png、doc、docx格式", "error");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 16;
      if (!isLt2M) {
        this.$message("上传文件大小不能超过 16MB", "error");
      }
      return isLt2M;
    },

    goback() {
      this.$router.go(-1);
    },
    // 提交审核文件
    uploadCheckFiles(item, response) {
      var uploadedFile = {
        name: response.data.name,
        url: response.data.url,
        orderid: item.orderid || 1  // Assuming 'item.orderid' is '1' based on your current code.
      };
      this.checkFiles.push(uploadedFile);
    },


    changeMaterImg(item, response) {

      var uploadedFile = {
        name: response.data.name,
        url: response.data.url,
        orderid: item.orderid
      };
      item.fileList.push(uploadedFile);
    },
    // 关闭模态框前的处理
    handleClose(done) {
      // 可以在这里添加关闭前的逻辑，如清理资源
      this.selectedFile = null;
      done();
    },

    removeImg(item, file, fileList) {
      const index = fileList.findIndex(f => f === file);

      this.$confirm(`是否确认删除文件 ${file.name}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        fileList.splice(index, 1);
      }).catch(() => {

      });
    },
    // 删除上传的图片
    delbtn(item, fileIndex) {

      // 当已经提交状态不允许删除
      if (this.isValueInList) {
        const fileToRemove = item.fileList[fileIndex];
        this.$confirm(`是否确认删除文件 ${fileToRemove.name}？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          item.fileList.splice(fileIndex, 1);
          // fileToRemove.isdelete = 1;
          // fileToRemove.token = this.$store.state.token;
          let param = {
            token: this.$store.state.token,
            id: fileToRemove.id,
            isdelete: 1
          }

          enterpriseFileDel(param)
            .then((res) => {
              this.$message.success("删除成功！");
              // this.getIdAndToken();          
            })
            .catch((err) => {

            });
        }).catch(() => {
          this.$message("删除失败");
        });
      } else {
        // 审查中的状态不给删除
        this.$message.warning("当前为审查中状态，不能被删除!", "警告")
      }
    },
    // 提交审查
    addmit() {
      // TODO 这里需要增加对提交文件数量的判断，应该当没有提交文件时不给提交，尤其是必须提交的文件
      this.disabled = true;
      this.$confirm(`确定提交？ `, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'information'
      }).then(() => {
        let files_mb = [];
        for (let item of this.checkdata.uploadfiles) {
          files_mb = files_mb.concat(item.fileList);
        }
        // 设置当前阶段
        files_mb.forEach((item) => {
          item.type = this.checkdata.stagetypeid;
          if (
            this.stageid == 1
          ) {
            item.stageid = this.stageid + 1;
          } else {
            item.stageid = this.stageid;
          }

        });

        let lctype = 0;
        if (this.activities?.length > 0) {
          lctype = this.activities[this.activities.length - 1].type;
        }

        let types = [];
        for (let i = 0; i < files_mb.length; i++) {
          types.push(i + 1);
        }

        let params = {
          token: this.$store.state.token,
          files: files_mb,
          sid: this.$route.query.id,
          status: "2.10",//初审提交
          type: "1",
          types: types.join(","),
        };

        enterpriseFiles(params)
          .then((res) => {
            this.$message.success("提交审查成功");
            this.getIdAndToken();
          })
          .catch((err) => {
            this.disabled = false;
          });
      }).catch(() => {
        this.$message("提交失败！");
      });




    },
    // 获取用户信息
    getIdAndToken() {
      this.id = this.$route.query.id;
      this.token = this.$store.state.token; //获取token
      this.isadmin = this.$store.state.user.type;
    }
  },
};
</script>

<style>
/deep/ .el-input {
  width: 250px;
}
</style>

<style scoped lang="scss">
.flowrow {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #d8d8d8;
  padding-top: 10px;

  .flowleft {
    font-size: 16px;
    font-weight: 400;
    color: #409eff;
    margin-right: 10px;
    cursor: pointer;
  }

  .flowright {
    color: #409eff;
    margin-top: 2px;
    cursor: pointer;
  }
}

.mbline {
  height: 35px;
  line-height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .neirong {
    width: 80%;
    cursor: pointer;
  }

  .neirongto {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .delbtn {
    cursor: pointer;
  }
}

.coianter {
  width: 100%;
  background-color: #f4f4f4f4;
  height: 800px;
}

.idteail {
  margin: 0 0 0px 0px;
  width: 100%;
  // height:60px;
  background-color: #ffffff;

  // box-shadow: #cccbcb 0px -1px 10px;
  .idtname {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    margin-left: 63px;
  }

  .back {
    margin-right: 23px;
    font-size: 20px;
  }
}

.title {
  background: white;
  width: 100%;

  .titleicon {
    margin: 10px;
    width: 4px;
    height: 24px;
    background: #3082e2;
  }

  .titletext {
    font-size: 22px;
    font-family: Adobe Heiti Std;
    color: #3082e2;
    line-height: 65px;
  }
}

.comit {
  padding: 20px 0;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: white;

  .baocun {
    margin: 0 25px;
  }
}

.name_input {
  width: 100%;
}

.name_input>.el-form-item {
  width: 100%;
}

.name_input>.el-form-item .el-input {
  width: 100%;
}

.borderstyle {
  border: 1px solid;
}

.centerstyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flexstyle {
  display: flex;
  flex-direction: row;
}

.tableTitle-tr {
  height: 60px;
  font-size: 16px;

  .tdtitle {
    width: 150px;
    border: 1px solid #3082e2;
    background: #eaf2fc;
    text-align: center;
  }

  .tdcontent {
    width: 500px;
  }

  .tdcontent2 {
    width: 300px;
  }

  .bianzhitd {
    width: 1415px;
  }
}

.tableTitle-tr2 {
  font-size: 16px;

  .tdtitle {
    width: 150px;
    border: 1px solid black;
    background: #eaf2fc;
    text-align: center;
  }

  .shijianstyle {
    text-align: center;
  }

  .tdcontent {
    width: 500px;
  }

  .tdcontent2 {
    width: 300px;
  }

  .bianzhitd {
    width: 1415px;
  }
}

.tiwen {
  height: 160px;
  box-shadow: 0px 6px 11px 2px rgba(72, 77, 84, 0.2);

  .tiwenText {
    width: 100%;
    height: 100%;
    background: rgba(93, 160, 236, 0.1);
    border: 0px;
    resize: none;
  }
}

.borderstyle {
  border: 1px solid;
}

table {
  border: 0;
  border-collapse: collapse;
}

.tableTitle-tr-pdf {
  height: 60px;
  font-size: 16px;
  border: 1px solid #000305;

  .tdtitle {
    width: 150px;
    border: 1px solid black;
    //background: #EAF2FC;
    text-align: center;
  }

  .tdcontent {
    border: 1px solid #000305;
    width: 500px;
  }

  .tdcontent2 {
    border: 1px solid #000305;
    width: 300px;
  }

  .bianzhitd {
    border: 1px solid #000305;
    width: 1415px;
  }
}

td {
  border: 1px solid #3082e2;
}

.filename {
  color: #888888;
  cursor: pointer;
}

.filename:hover {
  color: #3082e2;
}

.chfiles {
  border-top: 1px solid #d8d8d8;
  margin-top: 10px;
  padding-top: 10px;
}

.cgfleftto {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.cgfleftto:hover {
  color: #3082e2;
  cursor: pointer;
}

.flex-row {
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
}

.left-group {
  display: flex;
  align-items: center;
  /* 左侧的标题和时间在垂直方向上居中 */
}

.shehe {
  margin-left: auto;
  font-size: 16px;
  color: #999999;

}

.flexrow_tt {
  display: flex;
  flex-direction: row;
}

.checktitle {
  font-size: 18px;
  font-weight: bold;
  color: #999999;
  margin-right: 8px;
  /* 调整时间和标题的间距 */
}

.sendbtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.flexrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tilelft {
  font-size: 22px;
  font-weight: bold;
  color: #1a1a1a;
}

.tiecg {
  margin-bottom: 20px;
}

.lefttie {
  border-bottom: 1px solid #dbe9ef;
  padding: 5px;

  .ltleft {
    border-left: 4px solid #3082e2;
    padding-left: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #3082e2;
  }

  .ltright {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.leftcg {
  width: 64%;
}

.rightcg {
  width: 35%;
  overflow: hidden;
  padding-right: 20px;
  overflow-y: scroll;
  padding-left: 40px;
}

.rightcg::-webkit-scrollbar {
  display: none;
}

.linetie {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  margin-top: 15px;

  .lttime {
    font-size: 14px;
    font-weight: 400;
    color: #888888;
    margin-right: auto;
    /* 将时间和标题与右边的内容分开 */
  }

  .ltmanger {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    padding-right: 10px;
  }
}

.kuang {
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.29);
  margin: 10px 0;
  padding: 10px 15px;
}

.cr-card {
  .checkcont {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
  }
}

.tgbtn {
  padding: 10px 58px;
}

.leftfile {
  padding: 10px 20px;
  background-color: #ffffff;

  .filelistcg {
    .cgfiles {
      background: #f4f4f4;
      padding: 20px 15px;
      margin-bottom: 10px;

      .cgtop:nth-child(1) {
        border-bottom: 1px solid #dbe9ef;
      }

      .cgtop:nth-child(2) {
        margin-top: 10px;
      }

      .cgfleft {
        font-size: 16px;
        font-weight: 400;
        width: 80%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #666666;

        .beizhu {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          padding-left: 15px;
          height: 35px;
          line-height: 35px;
        }
      }

      .cgfrig {
        font-size: 14px;
        font-weight: 400;
        color: #f56c6c;
        cursor: pointer;
      }

      .cgfrig:hover {
        font-size: 16px;
        font-weight: 400;
        color: #d84747;
        cursor: pointer;
      }
    }
  }
}
</style>
